import React, { Fragment, ReactNode } from 'react';
import BaseComponent from '../../base/base-component';
import { Language } from '../../global/locale/locale-handler/locale-handler-enum';
import { Route } from '../../global/route-enum';
import Footer from '../footer/footer';
import SEO from '../seo/seo';
import WPHeader from '../wordpress-header/wp-header';
import { IWordPressPageLayoutProperties } from './wordpress-page-layout-interface';

export default class WordPressPageLayout extends BaseComponent<IWordPressPageLayoutProperties> {
  protected declareTranslateCollection(): string {
    return 'layout';
  }

  public render(): ReactNode {
    const { subtitle, title, children } = this.props;

    return (
      <Fragment>
        <SEO
          pageTitle={`${this.translate('siteName')} - ${title}`}
          description="Page Description"
          keywords={['state', 'democracy']}
          language={Language.EN_US}
        />
        <WPHeader menuItems={[]} />
        {children}
        <Footer
          menuItems={[
            { title: this.translate('about'), path: Route.ABOUT },
            { title: this.translate('media'), path: Route.MEDIA },
            { title: this.translate('takeAction'), path: Route.TAKE_ACTION },
            {
              title: this.translate('readTheReport'),
              path: Route.READ_THE_REPORT,
            },

            { title: this.translate('methodology'), path: Route.METHODOLOGY },
            {
              title: this.translate('data'),
              path: Route.DATA,
              isExternal: true,
            },

            {
              title: this.translate('stateOfReform'),
              path: Route.STATE_OF_REFORM,
            },
          ]}
        />
      </Fragment>
    );
  }
}
