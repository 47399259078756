import React, { ReactNode } from "react";
import BaseComponent from "../../base/base-component";
import { SolidButtonColorSchemas } from "./solid-button-enum";
import { ISolidButtonProperties } from "./solid-button-interface";
import "./solid-button.scss";

export default class SolidButton extends BaseComponent<ISolidButtonProperties> {
  public render(): ReactNode {
    const { colorSchema = SolidButtonColorSchemas.PURPLE } = this.props;
    const schemaClass: string = `is-${colorSchema}`;
    const classNames: string = ["c-btn", schemaClass].join(" ");

    return (
      <button className={classNames} type="button" onClick={this.props.onClick}>
        {this.props.children}
      </button>
    );
  }
}
